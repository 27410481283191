import React, { Component } from 'react';
import Siema from 'siema';

import s from '../styles/carousel.module.css';

export default class Carousel extends Component {
  componentDidMount() {
    this.siema = new Siema({
      selector: `.${this.props.name}-siema`,
      loop: true,
    });
  }

  prev = () => {
    this.siema.prev();
  };
  next = () => {
    this.siema.next();
  };

  generateCarousel = () => {
    const { images } = this.props;
    const imagesLength = images.length;

    return this.props.images.map((img, index) => (
      <div className={`row ${s.imgWrapper}`} key={`slika-${index}`}>
        <img
          className={`col-lg-2 d-none d-lg-block ${s.img__left}`}
          src={images[(index + imagesLength - 1) % imagesLength]}
          alt="Stambeno-poslovni objekat"
        />
        <img
          className="col-md-10 col-lg-7"
          src={img}
          alt="Stambeno-poslovni objekat"
        />
        <img
          className={`col-md-2 col-lg-3 d-none d-md-block ${s.img__right}`}
          src={images[(index + 1) % imagesLength]}
          alt="Stambeno-poslovni objekat"
        />
      </div>
    ));
  };

  render() {
    const { description, location } = this.props;

    return (
      <>
        <div className="position-relative row">
          <div className="col-12 col-md-10 pb-3">
            <p>{description}</p>
          </div>

          <div className="col-12">
            <div className="informations">
              <span className={s.dataPill}>
                <span className="icon-location"></span>
                {location}
              </span>
            </div>
          </div>

          <div className={[s.navigationButtons, s.navigationButtonsBottom].join(' ')}>
            <button onClick={this.prev}>
              <span className="icon-arrow-left"></span>
            </button>
            <button onClick={this.next}>
              <span className="icon-arrow-right"></span>
            </button>
          </div>
        </div>

        <div className={`${this.props.name}-siema ${s.carousel}`}>
          {this.generateCarousel()}
        </div>
      </>
    );
  }
}
